import { Global } from "@emotion/react"
import { Link } from "gatsby"
import { React, useState } from "react"
import { TabProvider } from "../contexts/tab-context"
import { useForm } from "react-hook-form"
import axios from "axios"
import Container from "../components/container"
import Header from "../components/navigation"
import ScrollTop from "../components/scroll-top-button"
import Submit from "../components/form/submit"
import Input from "../components/form/input2"
import ErrorMessage from "../components/form/error-message"
import tw, { css, GlobalStyles } from "twin.macro"
import ClipLoader from "react-spinners/ClipLoader"

const Layout = props => {

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      data_storage: false,
    },
  })

  const { children, location } = props
  const isHomePage = location.pathname === "/"

  // const postSeo = props.data?.seo || {}

  const mainMenu = [
    { id: "", label: "Home" },
    { id: "programmuebersicht", label: "Programm", url: "/programmuebersicht" },
    { id: "persons", label: "Mitwirkende und Speaker*innen", url: "/persons" },
    { id: "fachmesse", label: "Fachmesse", url: "/fachmesse" },
    { id: "haeufig-gestellte-fragen", label: "Häufig gestellte Fragen", url: "/haeufig-gestellte-fragen" },
    { id: "zusammenarbeit-und-zusammensein-am-gleichstellungstag", label: "Gemeinschaftsregeln", url: "/zusammenarbeit-und-zusammensein-am-gleichstellungstag" },
    { id: "#kontakt", label: "Kontakt" },
  ]

  const footerMenu = [
    {
      id: "qwe2123",
      label: "Impressum",
      url: "https://www.bundesstiftung-gleichstellung.de/impressum/",
    },
    {
      id: "qwe1231",
      label: "Datenschutz",
      url: "https://www.bundesstiftung-gleichstellung.de/datenschutzerklaerung/",
    },
  ]

  const submitForm = async (data) => {

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    }
    try {
      const response = await axios.post(
        `/api/newsletter`,
        JSON.stringify(data),
        options
      )
      setIsSuccessfullySubmitted(response.status === 200)
    } catch (e) {
      if (e.response.status >= 400) {
        if (e.response.status === 403) {
          setDuplicateUserError(true)
          return
        }
        setSubmitError(true)
      }
    }
  }

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [duplicateUserError, setDuplicateUserError] = useState(false)

  return (
    <TabProvider>
      <GlobalStyles />
      <Global
        styles={css`
          body {
            ${tw`antialiased font-regular`}
            color: #25303B;
          }
          html {
            scroll-padding-top: 96px;
            @media (prefers-reduced-motion: no-preference) {
              scroll-behavior: smooth;
            }
            @media (min-width: 1024px) {
              scroll-padding-top: 172px;
            }
            @media (min-width: 1240px) {
              scroll-padding-top: 124px;
            }
            /*
            @media (min-width: 1240px) {
              font-size: calc(12.25px + 0.3vw);
              -webkit-marquee-increment: 0vw;
            }
            @media (min-width: 1920px) {
              font-size: 18.007px;
            }
            */
          }
        `}
      />
      <div
        tw="relative flex min-h-screen flex-col items-stretch"
        className="global-wrapper"
        data-is-root-path={isHomePage}
      >
        <Header mainMenuItems={mainMenu} footernMenuItems={footerMenu} />
        <main
          css={[tw`flex-grow`, !isHomePage && tw`mt-28 lg:mt-48`]}
          role="main"
        >
          {children}
          <ScrollTop />
        </main>

        <footer tw="flex-shrink-0 mt-12" role="contentinfo">
          <div
            css={[isHomePage ? tw`bg-white` : tw`bg-sand-500`]}
          >
            <Container>
              <div tw="grid grid-cols-12 gap-6 py-4 md:lg:py-14 lg:py-18">

                <div tw="col-span-12 md:col-span-6 lg:col-span-6">
                  <div tw="font-bold text-3xl sm:text-3xl  md:text-3xl lg:text-4xl">
                    Sie möchten Informationen aus der Bundesstiftung Gleichstellung erhalten?
                  </div>
                </div>

                {!isSuccessfullySubmitted && (
                  <div tw="col-span-12 md:col-span-6 md:place-self-end lg:col-span-6">

                    <form onSubmit={handleSubmit(submitForm)}>
                      <div tw="w-full">

                        <div tw="pr-3">

                          <input
                            {...register("email", {
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              required: true,
                            })}
                            css={[
                              tw`mb-4 mr-3 w-64 border-2 border-transparent px-2 md:px-3 pt-2.5 pb-2.5 text-base outline-none placeholder:text-sm placeholder:text-anthracite-500 invalid:border-magenta-500 focus:border-anthracite-500 focus:ring-transparent disabled:bg-sand-200`,
                              isHomePage ? tw`bg-sand-500` : tw`bg-white`,
                            ]}
                            type="email"
                            placeholder="Ihre E-Mail Adresse*"
                          />

                          <button
                            type="submit"
                            disabled={isSubmitting}
                            tw="h-12 inline-block bg-lilac-500 px-7 py-2 font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                          >ANMELDEN
                            {isSubmitting && (
                              <span tw="px-2">
                                <ClipLoader color={"currentColor"} size={20} />
                              </span>
                            )}
                          </button>


                          {submitError ? (
                            <ErrorMessage
                              message={
                                "Leider gab es einen Fehler. Bitte versuchen Sie es noch einmal oder kontaktieren Sie uns unter: "
                              }
                            >
                              <a
                                href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                                tw="text-base"
                              >
                                gleichstellungstag@bundesstiftung-gleichstellung.de
                              </a>
                            </ErrorMessage>
                          ) : null}
                          {duplicateUserError ? (
                            <ErrorMessage message="Die*der Nutzer*in mit dieser E-Mail-Adresse wurde bereits registriert." />
                          ) : null}

                        </div>

                        {errors?.email && errors?.email.type === "required" && (
                          <div tw="top-0 left-0 text-sm text-red-500">
                            Dies ist ein Pflichtfeld
                          </div>
                        )}
                        {errors?.email && errors?.email.type === "pattern" && (
                          <div tw="top-0 left-0 text-sm text-red-500">
                            Dieses Feld muss eine gültige E-Mail-Adresse enthalten
                          </div>
                        )}

                        <div tw=" pt-4">
                          <label tw="flex cursor-pointer items-center">
                            <input
                              tw="h-5 w-5 flex-shrink-0 cursor-pointer appearance-none border border-anthracite-500 bg-white bg-contain bg-center bg-no-repeat align-top text-lilac-500 transition duration-200 checked:border-lilac-500 checked:bg-lilac-500 focus:outline-none focus:ring-2 focus:ring-lilac-500 dark:border-gray-600 dark:bg-white dark:ring-offset-gray-800 dark:focus:ring-lilac-500"
                              type="checkbox"
                              {...register("data_storage", { required: true, })}
                            />
                            <span tw="ml-2 flex cursor-pointer flex-wrap text-sm md:ml-3">
                              Ich habe die Datenschutzerklärung gelesen und erkenne diese an.*
                            </span>
                          </label>

                          {errors?.data_storage && (
                          <div tw="top-0 left-0 text-sm text-red-500">
                            Dies ist ein Pflichtfeld
                          </div>
                          )}

                          <div tw="top-0 left-0 mt-5 text-sm">
                            * Pflichtfelder
                          </div>
                        </div>

                      </div>

                    </form>
                  </div>
                  )}

                  {isSuccessfullySubmitted && (
                    <div tw="p-15 col-span-12 md:col-span-6 md:place-self-end lg:col-span-6">
                      <span>
                        Vielen Dank für Ihre Anmeldung. Zur Bestätigung Ihrer Anmeldung erhalten Sie eine E-Mail.
                      </span>
                    </div>
                  )}

              </div>

              <div tw="grid grid-cols-12 gap-6 px-2 py-10 md:lg:py-14 lg:py-18">
                <div tw="col-span-12 md:col-span-6 lg:col-span-3">
                  <div tw="">
                    Bundesstiftung Gleichstellung
                    <br />
                    Karl-Liebknecht-Str. 34
                    <br />
                    10178 Berlin
                  </div>
                </div>
                <div tw="col-span-12 md:col-span-6 md:place-self-end lg:col-span-4">
                  <div tw="">
                    <a href="tel:+4930994057026" tw="text-link-negative">
                      +49 30 994 0570-26
                    </a>
                    <br />
                    <a
                      href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                      tw="text-link-negative"
                    >
                      gleichstellungstag@bundesstiftung-gleichstellung.de
                    </a>
                  </div>
                </div>
                <ul tw="col-span-12 flex flex-wrap space-x-4 text-lg font-bold md:col-span-6 md:space-x-5 md:text-xl lg:col-span-5 lg:justify-end lg:place-self-end">
                  {footerMenu.map(({ label, url, id }) => (
                    <li tw="" className="global-nav-item" key={`nav-${id}`}>
                      <a
                        href={url || `/`}
                        tw="py-3 px-0.5 navLink-animated underline-lillac"
                      >
                        {label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Container>
          </div>
        </footer>
      </div>
    </TabProvider>
  )
}

export default Layout
